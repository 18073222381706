body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.thinHeading{
  font-weight: 300;
}

.iconHeading{
  padding-left: 10px;
}

.heroSection{
  padding-top: 3%;
  padding-bottom: 3%;
}

.uploadOuterSection{
  /*background-color: #59197F;*/
  background-color:#4f2c54;
  color: white;
  /*border-radius: 5px;*/
  height: 275px;
  padding-top: 1%;
  padding-left: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  resize: vertical;
  overflow: auto;
  margin-bottom: 2.5%;
  cursor: pointer;
}

.uploadInnerSection{
  border: 2px dashed #7F7F7F;
  /*background-color: #62337F;*/
  background-color:#4f2c54;
  height: 95%;
  width: 99%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  resize: vertical;
  overflow: auto;
}



.uploadLabel{
  height: 100%;
  width: 100%;
  text-align: center;
}

.uploadIcons{
  font-size: 300%;
  font-weight: 300;
}

.lightgray{
  background-color: lightgrey;
}

.panel-collapse {
  max-height: 500px;
  overflow: hidden;
  transition: max-height .5s ease;
}
.panel-close {
  max-height: 0;
}
.panel-heading{
  border-color: purple;
  border-style: solid;
  padding:5%;
  border-radius: 5px;

}

.tutorialPopup{
  z-index: 500;
  position: fixed;
  bottom: 3%;
  right: 3%;
}

.tutorialPopupButton{
  background-color: #3256a8;
  color: white;
  font-weight: bold;
  padding: 20px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  cursor: pointer;
  /*height: 100px;*/
  /*width: 50px;*/
}

.uploadProgressBar{
  width: 50%;
  text-align: center;
  margin: 0 auto;
}

.fileDropTarget{
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  resize: both;
  margin-bottom: 2%;
}

.fileDropSource{
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid white;
  border-radius: 5px;
  background-color: white;
  height: 90%;
  width: 90%;
}

.fileDropSourceDrag{
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid white;
  border-radius: 5px;
  background-color: white;
  height: 90%;
  width: 90%;
  -ms-transform: rotate(20deg); /* IE 9 */
  -webkit-transform: rotate(20deg); /* Safari */
  transform: rotate(20deg);
}

.linkStyle{
  color: cornflowerblue;
  cursor: pointer;
}
#uploadFilesSampleTableContainer{
  width: 100%;
  overflow-y: auto;
  margin-top: 30px;
  padding-bottom: 30px;
}
#uploadFilesSampleTableContainer{
  width: 100%;
  overflow-y: auto;
  margin-top: 30px;
  padding-bottom: 30px;
}

#uploadFilesSampleTable{
  /*overflow-x: scroll;*/
  width: auto;
}


#uploadFilesSampleTableContainer,#uploadFilesSampleTable{
  transform:rotateX(180deg);
  -moz-transform:rotateX(180deg); /* Mozilla */
  -webkit-transform:rotateX(180deg); /* Safari and Chrome */
  -ms-transform:rotateX(180deg); /* IE 9+ */
  -o-transform:rotateX(180deg); /* Opera */
}

.linkStyle:hover{
  text-underline: blue;
  color: blue;
}

.app{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.roundedButton{
  border-radius: 40px;
}

.downloadFileIndex{
  /*background-color: #ffdb99;*/
  /*border: 1px solid #ffdb99;*/
  border-radius: 5px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto auto;
  grid-template-areas:
          "header header"
          "fileName removeDuplicates"
          "downloadButton downloadButton"
          "proBlockMessage proBlockMessage";
}

.proBlockMessage{
  grid-area: proBlockMessage;
}

.mergedFileNameDiv{
  grid-area: fileName;
  justify-self: end;
  margin-right: 10%;
  align-self: center;
}

.removeDuplicatesDiv{
  grid-area: removeDuplicates;
  margin-left: 5%;
  align-self: center;
  font-size: 120%;
}

.downloadButtonDiv{
  margin-top: 25px;
  margin-bottom: 25px;
  grid-area: downloadButton;
}

.downloadHeader{
  padding-top: 12px;
  padding-bottom: 20px;
  text-align: center;
  grid-area: header;
}

.editOptionDiv{
  /*margin-bottom: 10px;*/
  /*height: 40px;*/
  /*margin-top: 30px;*/
  margin-bottom: 30px;
  display: flex;
  align-items: center;

}

.importSheetOptions{
  padding-top: 10px;
  padding-bottom: 10px;
}

.wordwrap{
  word-break: break-all;
  word-wrap: break-word;
}

.semanticBorder{
  margin: 0em;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  -webkit-transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.8);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}

.focusBorder{
  border-color: #85B7D9;
  background: #FFFFFF;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pricingPage{
  min-height: 100vh;
}

.pricingGrid{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-template-areas:
          "freePricingCard paidPricingCard";
  grid-column-gap: 25px;
}

.freePricingCard{
  grid-area: freePricingCard;
  /*margin: 0 auto;*/
}

.paidPricingCard{
  grid-area: paidPricingCard;
  /*margin: 0 auto;*/
}

.customPricingCard{
  grid-area: customPricingCard
}

.pricingCard{
  /*padding-left: 30%;*/
  /*padding-right: 30%;*/
  padding-top: 25px;
}

.desc {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.desc li {
  border-bottom: 1px solid #eee;
  padding: 10px;
  text-align: center;
}

.closeIconClass{
  height: 25px;
  width: 25px;
  float: right;
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
}

.closeIconClass:hover{
  background-color: #DCDCDC;
  border-radius: 50%;
}

.closeIconClass > i{
  padding: 0;
}

.footerShareGrid{
  display: grid;
  grid-template-columns: 10% auto auto auto auto;
  grid-template-rows: auto;
  grid-template-areas: "footerEmpty twitter facebook reddit linkedin email";
}

.socialMediaButton{
  cursor: pointer;
}
.footerEmptySpace{
  grid-area: footerEmpty;
}
#twitterButton{
  grid-area: twitter;
}

#facebookButton{
  grid-area: facebook;
}

#redditButton{
  grid-area: reddit;
}

#linkedinButton{
  grid-area: linkedin;
}

#emailButton{
  grid-area: email;
}

.row {
  display: flex;
  flex-wrap: wrap;
  /*margin-right: -15px;*/
  /*margin-left: -15px;*/
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.uploadHeading{
  margin-top: -5px;
}

.downloadFileInputs{
  padding-bottom: 10px;

}

#toggleMergeDiv{
  border-radius: 5px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-template-areas:
          "append join";
}

#toggleAppendDiv{
  grid-area: append;
}

#toggleJoinDiv{
  grid-area: join;
}

.toggleMergeButtonDiv{
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

#joinSelectDiv{
  display: grid;
  grid-template-columns: 33% 33% 34%;
  grid-template-rows: auto;
  grid-template-areas:
          "file1 joinType file2"
}

#joinFileSelect1Div{
  grid-area: file1;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

#joinTypeDiv{
  grid-area: joinType;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

#joinFileSelect2Div{
  grid-area: file2;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

#columnSelectRow{
  display: grid;
  grid-template-columns: 33% 33% 34%;
  grid-template-rows: auto;
  grid-template-areas:
          "columnFile1 removeColumnRow columnFile2"
}

#columnFile1{
  grid-area: columnFile1;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

#columnFile2{
  grid-area: columnFile2;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

#removeColumnRow{
  grid-area: removeColumnRow;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

.fileOptions{
  padding-bottom: 10px;
}

.mergedSampleOptionsRowDiv{
  display: grid;
  grid-template-columns: 33% 34% 33%;
  grid-template-rows: auto;
  grid-template-areas:
          "editOptions explain mergedToggle"
}

.mergedEditOptionsDiv{
  grid-area: editOptions;
}

.mergedExplainDiv{
  grid-area: explain;
  text-align: center;
}

.mergedToggleDiv{
  grid-area: mergedToggle;
}

.editFileBody{
  padding-left: 8px;
}

.editFileInput{
  /*font-size: 125%;*/
}

#editFileCheckLabel{
  cursor: pointer;
  /*margin-bottom: -3px;*/
  padding-left: 7px;
}

.appendHelpOptions{
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-template-areas: "helpReadFileButton helpFileOptions";
}

.appendHelpReadFileButton{
  grid-area: helpReadFileButton
}

.appendHelpFileOptions{
  grid-area: helpFileOptions
}

.infoPagesHero{
  text-align: center;
}

#pricingHero{
  color:white;
  background: linear-gradient(24deg, rgba(106,210,252,1) 13%, rgba(181,0,210,1) 92%);
}

.appSupportPage{
  min-height: 100vh;
}

#aboutHero {
  color: white;
  text-align: center;
  font-size: 20px;
  background: linear-gradient(24deg, rgba(106,210,252,1) 13%, rgba(181,0,210,1) 92%);
  padding-bottom: 60px;
  padding-top: 60px;
}

#helpIndex{
  min-height: 100vh;
}

.rotatePlus{
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Safari prior 9.0 */
  transform: rotate(90deg); /* Standard syntax */
}

.sampleBelowButton{
  text-align: center;
  background-color: cornflowerblue;
  /*background-image: linear-gradient(-90deg, blue, lightblue);*/
  color: white;
  font-weight: 800;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  border-radius: 25px;
  cursor: pointer;
  padding: 10px;
  border: none;
}

.joinicons-innerjoin, .joinicons-leftjoin, .joinicons-rightjoin, .joinicons-fulljoin{
  padding-top: 5px;
  padding-right: 5px;
  font-size: 150%;
}

.joinSelectText{
  margin-top: -50px;
}

#joinSelectType{
  display: grid;
  grid-template-columns: 20% 75%;
  grid-template-rows: auto;
  grid-template-areas:
          "icon text";
}

#joinSelectIcon{
  grid-area: icon;
}

#joinSelectText{
  grid-area: text;
}

#editOptionsTable{
  margin-top: 30px;
  padding-left: 50px;
  padding-bottom: 30px;
  margin-right: 50px;
  overflow-x: scroll;
}

.segment-raised{
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.segment-raised-purple{
  box-shadow: 0 10px 20px #fff6ff, 0 6px 6px  purple;
}

.segment-raised-blue{
  box-shadow: 0 10px 20px #e5e5ff, 0 6px 6px  blue;
}

.segment-no-margin{
  background: #fff;
  border-radius: 2px;
  padding: 20px;
}

.landingExplainDesc{
  color: #59197f;
  font-size: 125%;
  text-align: center;
  font-weight: 500;
}

.landingJoinExplainDesc{
  color: darkblue;
  font-size: 125%;
  text-align: center;
  font-weight: 500;
}

.docSectionPlain, .docSectionHover{
  border-radius: 8px 8px 8px 8px;
  padding: 10px;
  border: 1px lightgrey solid;
}

.docSectionHover:hover{
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.proBlockMessageBox{
  border-radius: 8px 8px 8px 8px;
  border: 1px lightgrey solid;
}

.proBlockMessageWarning{
  border-radius: 8px 8px 0 0;
  animation: WARNING-BLINK 1s infinite;
  padding-bottom: 10px;
  padding-top: 10px;
  color: white;
}

#proBlockMessageIcon{
  text-align: center;
  font-size: 125%;
}

#proBlockMessageHeader{
  font-weight: 300;
  text-align: center;
}

#proBlockMessageHeader > h3{
  font-size: 125%;

}

.proBlockMessageButton{
  text-align: center;
  padding-top: 20px;
}

#proBlockButtonText{
  font-size: 125%;
}

.proBlockSignIn{
  padding-bottom: 20px;
  text-align: center;
}


@keyframes WARNING-BLINK{
  0%		{ background-color: #aa8cc5;}
  25%		{ background-color: #aa8cc5;}
  50%		{ background-color: #663096;}
  75%		{ background-color: #663096;}
  100%	    { background-color: #663096;}
}

.inputError{
  color: red
}

.guidesIndex{
  min-height: 100vh;
}

.guidesMenu{
  border: 1px solid lightgrey;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.guidesMenuItem{
  border: 1px solid lightgrey;
  text-align: center;
  cursor: pointer;
  padding: 15px 0 15px 0;
}

.guidesMenuHeader{
  font-weight: 300;
  font-size: 190%;
}

.menuItemSelected{
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  background-color: lightgrey;
}

@media only screen and (max-width: 641px) {
  .guidesDisplayIndex{
    margin: 5px 10px 150px 10px;
    /*padding: 30px;*/
    /*display: grid;*/
    /*grid-template-areas: "displayHeader" "display";*/
    /*width: 50%;*/
    /*margin: 0 auto;*/
    /*margin-bottom: 150px;*/
    /*margin-top: 50px;*/
  }

  .guidesDisplayHeader{
    grid-area: displayHeader;
    padding-bottom: 25px;
  }
  .guidesDisplay{
    grid-area: display;
  }

  .guidesDisplayVideo{
    grid-area: displayVideo;
  }

}

@media only screen and (min-width: 641px) {
  .guidesDisplayIndex{
    width: 50%;
    margin: 0 auto;
    margin-bottom: 150px;
    margin-top: 50px;
    /*padding: 70px;*/
    /*display: grid;*/
    /*grid-template-rows: auto auto;*/
    /*!*grid-template-columns: 50% 50%;*!*/
    /*grid-template-areas: "displayHeader" "display";*/
    /*grid-column-gap: 75px;*/
    /*margin: 5px 10px 150px 10px;*/
  }

  .guidesDisplayHeader{
    grid-area: displayHeader;
    padding-bottom: 40px;
  }
  .guidesDisplay{
    grid-area: display;
    padding-bottom: 250px;
  }

  .guidesDisplay p {
    font-size: 125%;
    margin-bottom: 40px;
  }

  .guidesDisplayVideo{
    grid-area: displayVideo;
  }
  .guidesDisplay li{
                       font-size: 125%;
                       margin-bottom: 10px;
                     }
}

@media only screen and (min-width: 641px) {
  .image {
    margin: 0 auto;
    width: 50%;
  }
}

@media only screen and (max-width: 641px) {
  .image {
    margin: 0 auto;
    width: 100%;
  }
}

.accordion{
  border: 1px solid lightgrey;
  background-color: whitesmoke;
  border-radius: 5px;
  padding: 5px;
}

.accordionHeader{
  /*border-bottom: 1px solid lightgrey;*/
  display: grid;
  grid-template-columns: 95% 5%;
  grid-template-rows: auto;
  grid-template-areas: "accordionHeaderText accordionHeaderIcon";
  cursor: pointer;
}

.accordionHeaderText{
  grid-area: accordionHeaderText;
  padding-left: 15px;
}

.accordionHeaderIcon{
  grid-area: accordionHeaderIcon;
}

.accordionHeaderIcon > i {
  float: right;
  top: 50%;
}

.accordionBody{
  padding: 15px;
}

#scrollButton{
  position: fixed;
  bottom: 20px;
  right: 100px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: mediumpurple;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 50px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.priceAmount{
  font-size: 175%;
}

.priceIdentifier{
  display: block;
  font-size: 85%;
}

#supportFormClearAllButton{
  float: right;
  border-radius: 12px;
  font-size: 80%;
  cursor: pointer;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

#subjectCharLeft{
  color: gray;
  font-style: italic;
}

#supportUploadFiles{
  background-color: #0088ff;
  color: white;
}

#supportUploadFiles:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

.inputError{
  color: red
}

.supportFormLabel{
  font-size: 110%;
}

.requiredLabel{
  padding-left: 3px;
  padding-right: 3px;
  color: red;
}

.buttonRaw {
  border: none;
  padding: 5px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.pricingFeature{
  text-align: center;
  border: 1px solid lightgrey;
  border-radius: 15px;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.19), 0 2px 2px rgba(0,0,0,0.23);
}

.guidesCardIndex{ min-height: 100vh;
 }

.guidesLayout{
  margin-right: 50px;
  margin-bottom: 250px;
  text-align: center;
}

.guidesCard{
  font-family: museo sans rounded, "sans-serif";
  color: black;
  padding: 25px 10px 25px 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  margin-top: 25px;
  border-radius: 10px;
  background-color: whitesmoke;
  position: relative;
  margin-left: 40px;
  width:auto;
  height:100%;
}
.guidesCard:hover{
  box-shadow: 0 8px 32px 0 rgba(0,0,0,0.2);
}
.guidesCardImg{
  width: 50%;
  height: 150px;
  margin: 0 auto;
  padding-top:25px;
}
.guidesCardBg{
  background-color: orangered;
  padding-top: 20px;
  border-radius: 10px;
  padding-bottom: -40px;
  position: relative;
}

.tutorialShare{
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding: 25px;
  /*height: 100px;*/
  /*width: 50px;*/
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  float: left;
  margin: 10px 0 0 50px;
  position: fixed;
  text-align: center;
}
.commentSection{
  margin-top: 75px;
  border-top: 1px solid lightgrey;
  padding-top: 25px;
}

