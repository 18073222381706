@import url(//db.onlinewebfonts.com/c/a83356d725f309d4c24ac2daca70cd64?family=Museo+Sans+Rounded+300);
@charset "UTF-8";

@font-face {
  font-family: "joinicons";
  src:url("fonts/joinicons.eot");
  src:url("fonts/joinicons.eot?#iefix") format("embedded-opentype"),
    url("fonts/joinicons.woff") format("woff"),
    url("fonts/joinicons.ttf") format("truetype"),
    url("fonts/joinicons.svg#joinicons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "joinicons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="joinicons-"]:before,
[class*=" joinicons-"]:before {
  font-family: "joinicons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.joinicons-rightjoin:before {
  content: "\61";
}
.joinicons-leftjoin:before {
  content: "\62";
}
.joinicons-innerjoin:before {
  content: "\63";
}
.joinicons-fulljoin:before {
  content: "\64";
}
.docSectionPlain segment-raised {
  width: 960px;
  position: relative;
  margin:0 auto;
  line-height: 1.4em;
}
.guidesLayout{
  font-family: 'Nunito', sans-serif;
}